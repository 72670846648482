import {Grid, Card, CardMedia, Avatar} from '@mui/material'
export const Services = ({title = 'Our Services', data}) => {
  return (
    <> 
    <div id='services' className='text-center'>    
      <div className='container'>
        <div className='section-title'>
          <h2>{title}</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>

        <div>
        <Grid container spacing={4} justifyContent='center'>
          { 
            data &&  data.map((d, i) => ( 
              <Grid item xs={11}   md={6}  lg={4} key={`${d.name}-${i}`} >           
                {/* <div key={`${d.name}-${i}`}  style={{marginTop: i === 6 ? 100 : 40}}> */}
                  {d.image ? <img src={d.image} alt='' className="services-image " /> : <i className= 
                  {d.icon}></i>}
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                {/* </div> */}
              </Grid>
            ))
          }        
        </Grid>          
        </div>      
       
      </div>
    </div>
    </>
  )
}
