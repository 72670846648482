import {Grid, Card, CardMedia, Avatar} from '@mui/material';

export const OurProducts = ({title = 'Our Product', data}) => {
  return (
    <> 
    <div id='products' className='text-center'>    
      <div className='container'>
        <div className='section-title'>
          <h2>{title}</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>

        {data.map((item, index) => {
          return (
            <div key={`${item.title}-${index}`}>
              <div className="container">
                <div>
                  <div>
                    {" "}
                    <img src="img/products/banner_1920x100.png" className="img-responsive" alt="" />{" "}
                  </div>
                  <div >
                    <div className="about-text">
                      <h2>{item.productShortName}</h2>
                      <h3>{item.productLongName}</h3>
                      <p>{item.productDescription}</p>

                      <div className="col-lg-6 col-sm-6 col-xs-12">
                      <h3>Why DREAMS?</h3>
                      <div className="list-style">
                        {/* <div className="col-lg-6 col-sm-6 col-xs-12"> */}
                          <ul>
                            {item.benefits.map((d, i) => (
                                  <li key={`${d}-${i}`}>{d}</li>
                                ))}
                          </ul>
                        {/* </div> */}
                        {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                          <ul>
                            {data
                              ? data.Why2.map((d, i) => (
                                  <li key={`${d}-${i}`}> {d}</li>
                                ))
                              : "loading"}
                          </ul>
                        </div> */}
                      </div>
                      </div> 

                      <div className="col-lg-6 col-sm-6 col-xs-12">
                      <h3>DREAMS FEATURES</h3>
                      <div className="list-style">
                        {/* <div className="col-lg-6 col-sm-6 col-xs-12"> */}
                          <ul>
                            {item.modules.map((d, i) => (
                                  <li key={`${d}-${i}`}>{d}</li>
                                ))}
                          </ul>
                        {/* </div> */}
                      </div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>        
            </div>   
          )
        })}

      </div>
    </div>
    </>
  )
}
