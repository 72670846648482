// third-party
import firebase from 'firebase/app';
import "firebase/firestore";
import 'firebase/storage'; 

import { appConfig } from '../../config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(appConfig.firebase);
}
// Initialize Firebase
const db = firebase.firestore();
export const createColleciton = (collectionName: string, docName: string, data: any) => {
    return new Promise((resolve, reject) => {
        db.collection(collectionName)
        .doc(docName)
        .set(data)
        .then(() => {
            console.log('FIRESTORE collection created!', { docName, data });
            resolve(true)
        })
        .catch(e => {
            console.error('FIRESTORE collection created error', e);
            reject(e);
        });
    })
};

export const updateCollection = (collectionName: string, docName: string, data: any) => {
    db.collection(collectionName)
        .doc(docName)
        .update(data)
        .then(() => {
            console.log('FIRESTORE updateCollection updated!', data);
        });
};
export const getColleciton = async (collectionName: string) => {
    try {
        return new Promise(resolve => {
            db.collection(collectionName).onSnapshot((snapshot) => {
                const collectionData: any = [];
                console.log("FIRESTORE getColleciton snapshot", {snapshot});

                snapshot.forEach((doc) => collectionData.push({ ...doc.data(), id: doc.id}));
                console.log("FIRESTORE getColleciton collectionData", {collectionName, collectionData});
                resolve(collectionData);
            });
        })
    } catch (e) {
        console.log("FIRESTORE getColleciton error", {e, collectionName})
        return null;
    }
};
export const getCollecitonByDocName = async (collectionName: string, docName: string) => {
    try {
        return await db.collection(collectionName).doc(docName).get();
    } catch (e) {
        console.log("FIRESTORE getCollecitonByDocName error", e)
        return null;
    }
}
export const handleUploadImage = (image:any, folderName = 'adhaar') =>
  new Promise((resolve, reject) => {
    let file = image;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const uploadTask = storageRef.child(`${folderName}/` + file.name).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (_snapshot) => {
        storageRef
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
          });
      },
      (error) => {
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
        //   console.log('image download url:', url);
          resolve(url); // this is important
        });
      }
    );
  });

