import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { OurProducts } from "./components/products";
import { Services } from "./components/services";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import { Gallery } from "./components/gallery";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import ChatBotComponent from "./components/chatbot/ChatBotComponent";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { createColleciton, getColleciton } from "./services/firebase";
import { Team } from "./components/Team";

import "react-multi-carousel/lib/styles.css";

const theme = {
  background: "white",
  color: "red",
  fontFamily: "Open sans",
  headerBgColor: "linear-gradient(to right, #5c81ed 0%, #69b3e7 100%)",
  headerFontColor: "#5c81ed",
  headerFontSize: "15px",
  botBubbleColor: "linear-gradient(to right, #5c81ed 0%, #69b3e7 100%)",
  botFontColor: "white",
  userBubbleColor: "white",
  userFontColor: "white",
};

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [isLoading, setDataLoader] = useState<boolean>(true);
  const [landingPageData, setLandingPageData] = useState<any>({});
  let [showChat, setShowChat] = useState(false);

  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };

  useEffect(() => {
    // createColleciton("dariff_app_data", "static_data", JsonData)
    //   .then(response => {
    //     console.info( "firebase dariff_app_data  created successfully!", response);
    //   })
    //   .catch(e => {
    //     console.error( "firebase dariff_app_data  creation failed!", e);
    //   });
    
    // get firebase data for local testing
    // setLandingPageData(JsonData);
    // setDataLoader(false);
    getColleciton("dariff_app_data").then((response) => {
      console.log(response, "response");
      if (Array.isArray(response)) {
        setLandingPageData(response[0]);
      } else {
        setLandingPageData(JsonData);
      }
      setDataLoader(false);
    })
    .catch(e => {
      console.error( "firebase getColleciton error", e);
      setLandingPageData(JsonData);
      setDataLoader(false);
    });
  }, []);

  return (
    <div>
      <Navigation />
      {isLoading ? (
        <div className="container"><h2>Loading data...</h2></div>
      ) : (
        <>
          <Header data={landingPageData.Header} />
          {/* <Features data={landingPageData.Features} /> */}
          <About data={landingPageData.About} />
          <OurProducts data={landingPageData.products} />
          <Services data={landingPageData.Services} />
          {/* <Gallery data={landingPageData.Gallery}/> */}
          <Testimonials data={landingPageData.Testimonials} />
          <Team data={landingPageData.Team} />
          <Contact data={landingPageData.Contact} />
        </>
      )}
      
      <div
        className="bot"
        style={{
          position: "fixed",
          right: 5,
          bottom: 5,
        }}
      >
        <div style={{ display: showChat ? "" : "none" }}>
          <ThemeProvider theme={theme}>
            <ChatBotComponent></ChatBotComponent>
          </ThemeProvider>
        </div>
        {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
        <div style={{ textAlign: "right" }}>
          {/* {!showChat 
            ? <button className="btn btn-custom btn-sm" onClick={() => startChat()}>Click to chat... </button> 
            : <button className="btn btn-custom btn-sm" onClick={() => hideChat()}>Click to hide... </button>
          } */}
          {/* commenting chatbot fornow */}
          {/* {!showChat 
            ?<img src={landingPageData.chat} width="100px"  onClick={() => startChat()} alt="showchatbot"/> 
            : <img src={landingPageData.hide} width="100px"  onClick={() => hideChat()} alt="hidechatbot"/>
          } */}
        </div>
      </div>
    </div>
  );
};

export default App;
