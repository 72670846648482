import React, { useState } from "react";

export const Navigation = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  window.onscroll = function() {
    const currentScrollPos = window.pageYOffset;
    setToggleMenu(false);
  }
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container-fluid'>
        <div className='navbar-header box-shadow'>
        <a className="navbar-brand logo" href='/'>
          <img
            src='img/logos/darifflogo_10.png'
            className='img-fluid'
            alt='logoImg'
          />
        </a>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          {/* <img src="img/about.jpg" className="img-responsive" alt="" />{" "} */}
         
          {/* <a className='navbar-brand page-scroll' href='#page-top'>
            Da Riff Private Limited
          </a>{' '} */}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li> */}
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            {/* <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li> */}
            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
  // return (
  //   <header id="site-header" className="header header-1">
  //     <div className="container-fluid">
  //       <div id="header-wrap" className="box-shadow">
  //         <div className="row">
  //           <div className="col-lg-12">
  //             <nav className="navbar navbar-expand-lg">
  //               <a className="navbar-brand logo" href={'/'}>
  //                 <img
  //                   id="logo-img"
  //                   className="img-fluid"
  //                   src="img/logos/darifflogo_10.png"
  //                   title="fortunekey logo 12"
  //                   alt="fortunekey-logo-12"
  //                 />
  //               </a>
  //               {/* <Link to={ROUTE_PATHS.CONTACT_US} title={"Route to contact us"}> */}
  //               {/* <a
  //                 className="d-block d-lg-none btn btn-theme btn-sm"
  //                 href="#contactus"
  //                 data-text="REGISTER"
  //                 style={{ fontSize: "12px", marginRight: "5px" }}
  //               >
  //                 <span>R</span>
  //                 <span>E</span>
  //                 <span>G</span>
  //                 <span>I</span>
  //                 <span>S</span>
  //                 <span>T</span>
  //                 <span>E</span>
  //                 <span>R</span>
  //               </a> */}
  //               {/* </Link> */}
  //               <button
  //                 className="navbar-toggler"
  //                 type="button"
  //                 data-bs-toggle="collapse"
  //                 data-bs-target="#navbarNav"
  //                 aria-controls="navbarNav"
  //                 aria-expanded={toggleMenu ? "true" : "false"}
  //                 aria-label="Toggle navigation"
  //                 onClick={() => setToggleMenu(!toggleMenu)} // this is temp fix, need to check bootstrap collapse class
  //               >
  //                 <span />
  //                 <span />
  //                 <span />
  //               </button>
  //               <div style={toggleMenu?{display:"block"}:{display:'none'}} className={"collapse navbar-collapse "} id="navbarNav">
  //                 <ul className="nav navbar-nav" style={{ marginLeft: "auto" }}>
  //                   <li className="nav-item">
  //                     {/* <Link to={ROUTE_PATHS.HOME} title="Route to home" onClick={() => setToggleMenu(false)}> */}
  //                       <a className="nav-link" href="#home">Home</a>
  //                     {/* </Link> */}
  //                   </li>
  //                   <li className="nav-item">
  //                     {/* <Link to={ROUTE_PATHS.WHY_CHOOSE_US} title="Route to why choose us" onClick={() => setToggleMenu(false)}> */}
  //                       <a className="nav-link" href="#WhyChooseUsScreen">Why Choose Us</a>
  //                     {/* </Link> */}
  //                   </li>
  //                   {/* <li className="nav-item">
  //                     <Link to={ROUTE_PATHS.BLOG_DETAILS}>
  //                       <a className="nav-link" href="#blogs">
  //                         Blogs
  //                       </a>
  //                     </Link>
  //                   </li> */}
  //                   <li className="nav-item">
  //                     {/* <Link to={ROUTE_PATHS.REPORTS} title="Route to reports" onClick={() => setToggleMenu(false)}> */}
  //                       <a className="nav-link" href="#reports">
  //                         Reports
  //                       </a>
  //                     {/* </Link> */}
  //                   </li>
  //                   {/* <li className="nav-item">
  //                     <Link to="/">
  //                       <a className="nav-link" >
  //                         About
  //                       </a>
  //                     </Link>
  //                   </li> */}
  //                   {/* <li className="nav-item">
  //                     <a className="nav-link" href="#pricing">
  //                       Pricing
  //                     </a>
  //                   </li>
  //                   <li className="nav-item">
  //                     <a className="nav-link" href="#contactus">
  //                       Contact Us
  //                     </a>
  //                   </li> */}
  //                 </ul>
  //               </div>
  //             </nav>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </header>
  // );
}
