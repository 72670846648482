import Carousel from "react-multi-carousel";

export const Team = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
        </div>
      </div>
      <div id="row" className="container">
        {props?.data ? (
          <>
            <Carousel responsive={responsive}>
              {props?.data?.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-3 col-sm-6 team team-list-item"
                >
                  <div className="thumbnail">
                    <img src={d?.img} alt="team-image" className="team-img" />
                    <div className="caption">
                      <h4>{d?.name}</h4>
                      <p>{d?.job}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </>
        ) : (
          "loading"
        )}
      </div>
    </div>
  );
};
